@import '@angular/material/prebuilt-themes/indigo-pink.css';

@media (min-width: 576px) {
  .nav-user-menu .user-image {
    float: none;
    line-height: 10px;
    margin-top: -8px;
  }
}

.nav-user-menu .user-image {
  border-radius: 50%;
  float: left;
  height: 2rem;
  margin-top: -4px;
  width: 2rem;
}

.form-group.required .col-form-label:after {
  color: #d00;
  content: "*";
  position: absolute;
  margin-left: 3px;
}

/* Sweet alert */
.swal2-title {
  display: inline-block !important;
}

body.swal2-height-auto {
  height: inherit !important;
}

.swal2-popup.swal2-toast {
  padding: 10px 12px !important;
  display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
  text-align: left !important;
  margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
  margin-bottom: 4px !important;
}

div.wrapper,
div.content-wrapper,
div.sidebar {
  min-height: 90vh;
}

table.table {
  * {
    font-size: 14px !important;
  }

  th,
  td {
    padding: 8px;
    vertical-align: middle;
  }
}

table-card-footer * {
  font-size: 14px;
}

/* scroll */

.scroll {
  overflow-x: scroll;
}

.submenu-color {
  background-color: #555b61 !important;
}

/* Фиксируем header и footer, если они тоже фиксированные */
app-header {
  position: fixed !important; /* Или другое позиционирование по вашему выбору */
  width: 100% !important;
  z-index: 998 !important; /* Убедитесь, что они остаются сверху */
}

/* Sidebar fix */
.filter-main-block2 {
  min-width: 600px !important; /* Ширина sidebar */
  z-index: 1100 !important;
  //overflow-y: auto !important; /* Прокрутка для длинного меню */
}

/* Sidebar fix */
.main-sidebar {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 250px !important; /* Ширина sidebar */
  z-index: 1040 !important;
  overflow-y: auto !important; /* Прокрутка для длинного меню */
}

.content-wrapper {
  margin-left: 250px !important; /* Ширина sidebar */
  padding: 0 !important; /* Отступ для контента */
  overflow-y: auto !important; /* Прокрутка содержимого страницы */
  //position: relative !important;
  //z-index: 1050 !important;
}

body.sidebar-collapse .content-wrapper {
  margin-left: 0 !important; /* Убираем отступ при скрытом sidebar */
}

/* Scroll fix */
.sidebar {
  max-height: 100% !important; /* Ограничиваем высоту, чтобы не вылезать за пределы окна */
  overflow-y: auto !important; /* Добавляем прокрутку */
}

.sidebar-menu .nav-item .nav-link {
  white-space: normal !important; /* Чтобы текст в меню переносился */
}

/* Добавляем прокрутку для длинных подменю */
.nav-treeview {
  max-height: 100vh !important; /* Максимальная высота подменю */
  overflow-y: auto !important; /* Прокрутка для подменю */
}

.sidebar-open .main-sidebar {
  transform: translateX(0) !important; /* Показываем меню при открытии */
  //transition: transform 0.3s ease-in-out !important;
  //z-index: 1050 !important;
}

.sidebar-collapse .main-sidebar {
  transform: translateX(-250px) !important; /* Прячем меню при закрытии */
  //transition: transform 0.3s ease-in-out !important;
}

.wrapper {
  background-color: #343A40FF;
}

.main-content {
  margin: 70px 15px !important;
  min-height: calc(100vh - 198px) !important;
}

//.mat-bottom-sheet {
//  min-width: 800px !important;
//  position: relative !important;
//  z-index: 2000 !important;
//}

.disabled {
  pointer-events: none;
}

.settings-column {
  min-width: 20%;
  text-align: center !important;
}

.form-horizontal {
  //z-index: 100 !important;
  //overflow-y: auto !important; /* Прокрутка для длинного меню */
}

//DO NOT TOUCH AREA
.do-not-touch {
  position: relative;
  border: 2px solid red;
  border-radius: 6px;
  padding: 10px;
}

.do-not-touch::before,
.do-not-touch::after {
  content: "Do not touch this area. Everything is created automatically. It may lead to a user data leak.";
  color: red;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;
  white-space: nowrap;
}

.do-not-touch::before {
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
}

.do-not-touch::after {
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
}

.do-not-touch::before,
.do-not-touch::after,
.do-not-touch span {
  background: white;
  padding: 2px 5px;
}

.do-not-touch span:last-of-type {
  left: auto;
  right: -14px;
  transform: rotate(0deg);
}

#valid-days.expired {
  color: red;
  font-weight: bold;
}

#valid-days.warning {
  color: orange;
  font-weight: bold;
}

#valid-days.valid {
  color: #007bff;
  font-weight: bold;
}
